<template>
  <g>
    <svg:style>
        .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#glass-pattern);
      }

      .cls-6 {
        fill: url(#glass-pattern);
      }

      .cls-7 {
        fill: url(#glass-pattern);
      }

      .cls-8 {
        fill: url(#glass-pattern);
      }

      .cls-9 {
        fill: url(#glass-pattern);
      }
    </svg:style>
    <linearGradient id="inox-gradient" x1="69.72" y1="245.71" x2="69.72" y2="54.84" xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient" x1="69.72" y1="95.22" x2="69.72" y2="59.79" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient id="glass-gradient-2" data-name="glass-gradient" x1="69.72" y1="134.78" x2="69.72" y2="96.82" xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-3" data-name="glass-gradient" x1="69.72" y1="203.73" x2="69.72" y2="165.77" xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-4" data-name="glass-gradient" x1="69.72" y1="168.16" x2="69.72" y2="132.4" xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-5" data-name="glass-gradient" x1="69.72" y1="240.77" x2="69.72" y2="205.33" xlink:href="#glass-gradient"/>
    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>

    <g id="V03">
      <polyline id="inox" 
                v-if="showInox"
                data-name="inox" 
                class="cls-4" 
                :points="`${doorLeftWidth1 + 87.11} ${doorTopHeight1 + 54.84} ${doorLeftWidth1 + 52.33} ${doorTopHeight1 + 54.84} ${doorLeftWidth1 + 52.13} ${doorTopHeight1 + 54.93} ${doorLeftWidth1 + 52.04} ${doorTopHeight1 + 55.13} ${doorLeftWidth1 + 52.04} ${doorTopHeight1 + 245.43} ${doorLeftWidth1 + 52.09} ${doorTopHeight1 + 245.57} ${doorLeftWidth1 + 52.18} ${doorTopHeight1 + 245.68} ${doorLeftWidth1 + 52.33} ${doorTopHeight1 + 245.71} ${doorLeftWidth1 + 87.11} ${doorTopHeight1 + 245.71} ${doorLeftWidth1 + 87.25} ${doorTopHeight1 + 245.68} ${doorLeftWidth1 + 87.35} ${doorTopHeight1 + 245.57} ${doorLeftWidth1 + 87.39} ${doorTopHeight1 + 245.43} ${doorLeftWidth1 + 87.39} ${doorTopHeight1 + 55.13} ${doorLeftWidth1 + 87.31} ${doorTopHeight1 + 54.93} ${doorLeftWidth1 + 87.11} ${doorTopHeight1 + 54.84}`"/>
      <polyline filter="url(#inset-shadow)" id="glass" 
                class="cls-5" 
                :points="`${doorLeftWidth1 + 56.99} ${doorTopHeight1 + 60.07} ${doorLeftWidth1 + 56.99} ${doorTopHeight1 + 94.95} ${doorLeftWidth1 + 57.05} ${doorTopHeight1 + 95.11} ${doorLeftWidth1 + 57.19} ${doorTopHeight1 + 95.22} ${doorLeftWidth1 + 57.36} ${doorTopHeight1 + 95.22} ${doorLeftWidth1 + 82.24} ${doorTopHeight1 + 87.92} ${doorLeftWidth1 + 82.39} ${doorTopHeight1 + 87.83} ${doorLeftWidth1 + 82.44} ${doorTopHeight1 + 87.66} ${doorLeftWidth1 + 82.44} ${doorTopHeight1 + 60.07} ${doorLeftWidth1 + 82.36} ${doorTopHeight1 + 59.88} ${doorLeftWidth1 + 82.16} ${doorTopHeight1 + 59.79} ${doorLeftWidth1 + 57.27} ${doorTopHeight1 + 59.79} ${doorLeftWidth1 + 57.08} ${doorTopHeight1 + 59.88} ${doorLeftWidth1 + 56.99} ${doorTopHeight1 + 60.07}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-2" 
                data-name="glass" class="cls-6" 
                :points="`${doorLeftWidth1 + 56.99} ${doorTopHeight1 + 100.49} ${doorLeftWidth1 + 56.99} ${doorTopHeight1 + 123.23} ${doorLeftWidth1 + 57.03} ${doorTopHeight1 + 123.39} ${doorLeftWidth1 + 57.16} ${doorTopHeight1 + 123.49} ${doorLeftWidth1 + 82.04} ${doorTopHeight1 + 134.76} ${doorLeftWidth + 82.23} ${doorTopHeight1 + 134.78} ${doorLeftWidth1 + 82.38} ${doorTopHeight1 + 134.69} ${doorLeftWidth1 + 82.44} ${doorTopHeight1 + 134.5} ${doorLeftWidth1 + 82.44} ${doorTopHeight1 + 97.09} ${doorLeftWidth1 + 82.4} ${doorTopHeight1 + 96.94} ${doorLeftWidth1 + 82.28} ${doorTopHeight1 + 96.84} ${doorLeftWidth1 + 82.12} ${doorTopHeight1 + 96.81} ${doorLeftWidth1 + 57.24} ${doorTopHeight1 + 100.21} ${doorLeftWidth1 + 57.07} ${doorTopHeight1 + 100.29} ${doorLeftWidth1 + 56.99} ${doorTopHeight1 + 100.49}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-3" 
                data-name="glass" 
                class="cls-7" 
                :points="`${doorLeftWidth1 + 56.99} ${doorTopHeight1 + 177.32} ${doorLeftWidth1 + 56.99} ${doorTopHeight1 + 200.07} ${doorLeftWidth1 + 57.07} ${doorTopHeight1 + 200.25} ${doorLeftWidth1 + 57.24} ${doorTopHeight1 + 200.35} ${doorLeftWidth1 + 82.12} ${doorTopHeight1 + 203.73} ${doorLeftWidth1 + 82.28} ${doorTopHeight1 + 203.71} ${doorLeftWidth1 + 82.4} ${doorTopHeight1 + 203.62} ${doorLeftWidth1 + 82.44} ${doorTopHeight1 + 203.45} ${doorLeftWidth1 + 82.44} ${doorTopHeight1 + 166.04} ${doorLeftWidth1 + 82.38} ${doorTopHeight1 + 165.87} ${doorLeftWidth1 + 82.23} ${doorTopHeight1 + 165.77} ${doorLeftWidth1 + 82.04} ${doorTopHeight1 + 165.78} ${doorLeftWidth1 + 57.16} ${doorTopHeight1 + 177.06} ${doorLeftWidth1 + 57.03} ${doorTopHeight1 + 177.17} ${doorLeftWidth1 + 56.99} ${doorTopHeight1 + 177.32}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-4" 
                data-name="glass" 
                class="cls-8" 
                :points="`${doorLeftWidth1 + 57.36} ${doorTopHeight1 + 168.16} ${doorLeftWidth1 + 82.24} ${doorTopHeight1 + 160.72} ${doorLeftWidth1 + 82.39} ${doorTopHeight1 + 160.62} ${doorLeftWidth1 + 82.44} ${doorTopHeight1 + 160.45} ${doorLeftWidth1 + 82.44} ${doorTopHeight1 + 140.11} ${doorLeftWidth1 + 82.39} ${doorTopHeight1 + 139.94} ${doorLeftWidth1 + 82.24} ${doorTopHeight1 + 139.83} ${doorLeftWidth1 + 57.36} ${doorTopHeight1 + 132.4} ${doorLeftWidth1 + 57.19} ${doorTopHeight1 + 132.4} ${doorLeftWidth1 + 57.05} ${doorTopHeight1 + 132.51} ${doorLeftWidth1 + 56.99} ${doorTopHeight1 + 132.67} ${doorLeftWidth1 + 56.99} ${doorTopHeight1 + 167.89} ${doorLeftWidth1 + 57.05} ${doorTopHeight1 + 168.05} ${doorLeftWidth1 + 57.19} ${doorTopHeight1 + 168.16} ${doorLeftWidth1 + 57.36} ${doorTopHeight1 + 168.16}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-5" 
                data-name="glass" 
                class="cls-9" 
                :points="`${doorLeftWidth1 + 82.24} ${doorTopHeight1 + 212.63} ${doorLeftWidth1 + 57.36} ${doorTopHeight1 + 205.33} ${doorLeftWidth1 + 57.19} ${doorTopHeight1 + 205.34} ${doorLeftWidth1 + 57.05} ${doorTopHeight1 + 205.44} ${doorLeftWidth1 + 56.99} ${doorTopHeight1 + 205.61} ${doorLeftWidth1 + 56.99} ${doorTopHeight1 + 240.48} ${doorLeftWidth1 + 57.03} ${doorTopHeight1 + 240.62} ${doorLeftWidth1 + 57.13} ${doorTopHeight1 + 240.72} ${doorLeftWidth1 + 57.27} ${doorTopHeight1 + 240.77} ${doorLeftWidth1 + 82.16} ${doorTopHeight1 + 240.77} ${doorLeftWidth1 + 82.3} ${doorTopHeight1 + 240.72} ${doorLeftWidth1 + 82.41} ${doorTopHeight1 + 240.62} ${doorLeftWidth1 + 82.44} ${doorTopHeight1 + 240.48} ${doorLeftWidth1 + 82.44} ${doorTopHeight1 + 212.9} ${doorLeftWidth1 + 82.39} ${doorTopHeight1 + 212.73} ${doorLeftWidth1 + 82.24} ${doorTopHeight1 + 212.63}`"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
  }
}
</script>
